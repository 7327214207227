<template>
    <div class="right-side">
        <h2>{{$t("AccountPage.AccountProcurementNotices.title")}}</h2>
        <div class="mb-4">{{$t("AccountPage.AccountProcurementNotices.description")}}</div>
        <form @submit.prevent="onSubmit()" autocomplete="off">
            <div class="form-group dropdown">
                <label for="codeInput">{{$t("ktru.ktru")}}</label>
                <div class="input-group">
                    <input type="text" class="form-control form-control-lg" id="codeInput" maxlength="17" :placeholder="$t('search.keyword')" v-model="lookingCode" @input="onInputCode">
                    <select v-model="city">
                        <option :value="null">{{ $t('ktru.all_regions') }}</option>
                        <option v-for="i in cities" :value="i" :key="i.id"> {{ getCodeName(i) }} </option>
                    </select>
                    <div class="input-group-append">
                        <button type="submit" class="btn btn-lg btn-zhako" :disabled="$v.$invalid">
                            <i class="icon-check2"></i> {{$t("AccountPage.AccountProcurementNotices.Add")}}
                        </button>
                    </div>
                </div>
                <div class="dropdown-menu" aria-labelledby="codeInput" style="min-width:100%; max-width:100%;overflow:hidden" v-bind:class="{ 'd-block': lookingCode && matchingCodes.length }">
                    <a class="dropdown-item" href="#" @click.prevent="onSelectCode(code)" v-for="code in matchingCodes.filter(c => c.code.length == 17)" :key="code.id">{{code.code + ' ' + getCodeName(code,true)}}</a>
                </div>
            </div>
        </form>

        <table-view
            :columns="myColumns"
            :data="codes"
            :startWith="startWith"
            :selectItem="nop"
            :onRemove="onRemove"
        >
            <template v-slot:head>
                <tr>
                    <th>№</th>
                    <th>{{$t("ktru.name")}}</th>
                    <th>{{$t("ktru.ktru")}}</th>
                    <th>{{$t("ktru.place")}}</th>
                    <th></th>
                </tr>
            </template>
            <template v-slot:last-column></template>
        </table-view>

        <beat-loader 
            v-if="beatLoad" 
            class="loader-center" 
            :color="'#553DB4'" 
            :loading="beatLoad" 
            :size="20" 
            :sizeUnit="'px'"
            />

        <paginate
            v-model="currentPage"
            :page-count="pageCount"
            :click-handler="changePage"
            :prev-text="'&laquo;'"
            :next-text="'&raquo;'"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :page-link-class="'page-link'"
            :prev-class="'page-item'"
            :prev-link-class="'page-link'"
            :next-class="'page-item'"
            :next-link-class="'page-link'"
            >
        </paginate>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import TableView from '@/components/ui/TableView.vue'
export default {
  components: { TableView },
    data() {
        return {
            beatLoad: false,
            lookingCode: null,
            requestingCodes: false,
            matchingCodes: [],
            selectedCode: null,
            cities: [],
            city: null,
            myColumns: {
                // column name : column label
                'index': '№', 
                'name': el => this.getCodeName(el.enstru15Id, true),
                'code': el => el.enstru15Id.code,
                'cato': el => el.myEnstruCityList && el.myEnstruCityList[0].dcityId && this.getCodeName(el.myEnstruCityList[0].dcityId)
            },
        }
    },
    validations: {
        lookingCode: { required, min: minLength(17), max: maxLength(17) }
    },
    mounted() {
        this.onShow();
    },
    methods: {
        onShow() {
            this.getProcurementCodes();
            this.getCities();
        },
        onInputCode() {
            if (this.requestingCodes === false && this.lookingCode.length > 3) {
                this.requestingCodes = true;
                let keywords = this.lookingCode.split(/[\s,;]+/).filter(s => s.length > 0).join(' ');
                this.$store.dispatch("LOAD_ENSTRU15_CODES", {
                    filter: {
                        code: keywords,
                        nameRu: keywords,
                        nameKz: keywords
                    }
                })
                .then(result => {
                    if (result.status == 200) {
                        this.matchingCodes = result.data.content;
                    }
                    this.requestingCodes = false;
                })
                .catch(() => {
                    this.requestingCodes = false;
                })
                ;
            }
        },
        onSelectCode(el) {
            this.selectedCode = el;
            this.lookingCode = el.code;
            this.matchingCodes = [];
        },
        onSubmit() {
            this.addProcurementCode(this.lookingCode);
        },
        onRemove(item) {
            this.$store.dispatch("DELETE_PROCUREMENT_CODE", item)
            .then(() => this.getProcurementCodes());
        },
        getProcurementCodes() {
            this.beatLoad = true;
            this.$store.dispatch("LOAD_PROCUREMENT_CODES")
            .then(() => {
                this.beatLoad = false;
            })
            ;
        },
        getCodeName(el, showDescription = false) {
            let name;
            switch(this.$i18n.locale) {
                case 'kz':
                    name = el.nameKz + (showDescription && el.detailsKz ? '; ' + el.detailsKz : '');
                    break;
                case 'ru':
                default:
                    name = el.nameRu + (showDescription && el.detailsRu ? '; ' + el.detailsRu : '');
            }
            return name;
        },
        changePage(number) {
            this.currentPage = number;
            this.getProcurementCodes();
        },
        hasTariff() {
            if (this.tariff && this.tariff.code === "FREE" ) {
                this.$toast.info( this.$t("AccountProcurementNotices.to_receive_emails_need_purchase") );
                return false;
            }
            if (this.tariff && this.tariff.code === "DEMO" && this.codes.length > 2) {
                this.$toast.info( this.$t("AccountProcurementNotices.3_codes_available_for_demo") );
                return false;
            }
            return true;
        },
        addProcurementCode(code) {
            
            if(!this.hasTariff()) {
                return;
            }

            this.$store.dispatch("LOAD_ENSTRU15_CODES", {
                filter: {
                    code: code
                }
            })
            .then(result => {
                if (result.status == 200 && result.data.totalElements == 1) {
                    this.$store.dispatch("ADD_PROCUREMENT_CODE", {
                        dcityList: [this.city],
                        enstru15Id: result.data.content[0]
                    })
                    .then(() => {
                        this.getProcurementCodes();
                        this.lookingCode = null;
                        this.city = null;
                    })
                    ;
                }
            })
            ;

        },
        getCities() {
            let params = {
                entity: 'DictionaryItem',
                filter: {
                    'dictionaryId.code': "cato", 
                    'parentId%isNull': 0
                },
                page: 0,
                length: 1000,
            };
            this.$store.dispatch('GET_CITIES', params)
                .then((r) => {
                    this.cities = r.content;
                })
                ;
        },
        nop() {}
    },
    computed: {
        ...mapGetters([
            'USER_TARIFF',
            'PROCUREMENT_CODES',
            'PROCUREMENT_CURRENT_PAGE',
            'PROCUREMENT_PAGE_COUNT',
            'PROCUREMENT_STARTWITH',
        ]),
        tariff() {
            return this.USER_TARIFF;
        },
        codes() {
            return this.PROCUREMENT_CODES;
        },
        currentPage: {
            get() {
                return this.PROCUREMENT_CURRENT_PAGE;
            },
            set(number) {
                this.$store.commit('SET_PROCUREMENT_CURRENT_PAGE', number);
            }
        },
        pageCount() {
            return this.PROCUREMENT_PAGE_COUNT;
        },
        startWith() {
            return this.PROCUREMENT_STARTWITH;
        },
    }
}
</script>