<template>
    <table class="table table-borderless table-hover">
        <thead>
            <slot name="head">
                <tr>
                    <th scope="col" v-for="(col, index) in columns" :key="index">{{ typeof col === 'string' ? col : index}}</th>
                </tr>
            </slot>
        </thead>
        <tbody>
            <tr v-for="(row,index) in data" :key="row.id" @click="selectItem(row)">
                <th v-if="'index' in columns" scope="row">{{startWith + index}}</th>
                <td v-for="(col,index) in getVisibleColumns()" :key="index">{{ typeof col === 'function' ? col(row) : row[col]}}</td>
                <td v-if="onRemove"><a href="#" @click.prevent="onRemove(row)"><i class="fas fa-trash"></i></a></td>
                <slot name="last-column"></slot>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: 'table-view',
    props: ['columns', 'data', 'startWith', 'selectItem', 'onRemove'],
    data() {
        return {
            visibleColumns: null,
        }
    },
    methods: {
        onClick(row) {
            console.log("row clicked. " + row.subject);
        },
        getVisibleColumns() {
            if (this.visibleColumns === null) {
                this.visibleColumns = Object.assign({}, this.columns);
                if ('index' in this.visibleColumns) {
                    delete this.visibleColumns.index;
                }
            }
            return this.visibleColumns;
        },
        getColumnValue(col, row) {
            if (typeof col === 'string') {
                return row[col];
            }
            if (typeof col === 'function') {
                return col(row);
            }

            return '';
        }
    }
}
</script>

<style lang="scss" scoped>
div{
    width: 200px;
    background: #000;
}
</style>